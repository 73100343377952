import * as React from 'react';
import { TextField } from '@material-ui/core';
import { Controller } from 'react-hook-form';

interface Props {
  label: string;
  defaultValue?: string;
  className?: string;
  control: any;
  error?: boolean;
  helperText?: string | undefined;
  name: string;
  rules?: any;
  rows?: number;
  type?: string;
  variant?: any;
}

const ZTextField: React.FC<Props> = ({ label, defaultValue, className, control, error, name, helperText, rules, rows, type, variant }) => {
  return (
    <div key={name} className={className}>
      <Controller
        key={name}
        as={
          <TextField
            error={error}
            fullWidth
            margin='dense'
            helperText={error ? helperText ?? 'This field is required' : undefined}
            multiline={type ? false : true}
            rows={type ? undefined : rows}
            type={type}
            variant={variant}
          />
        }
        rules={rules ?? { required: 'This field is required' }}
        autoComplete='false'
        label={label}
        name={name}
        control={control}
        defaultValue={defaultValue ?? ''}
      />
    </div>
  );
};

ZTextField.defaultProps = {};

export default ZTextField;
