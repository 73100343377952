import { Pricing } from './stock';
const moment = require('moment');

export class Reservation implements IReservation {
    accepted: boolean;
    completed: boolean;
    confidence: string;
    confirmed: boolean;
    currency: string;
    documentId: string;
    email: string;
    fcmToken: string;
    items: Item[];
    markForRefund: boolean;
    markForReview: boolean;
    password: string;
    pickUpDateTime: string;
    refundCompleted: boolean;
    refundReviewComment: string;
    refunded: boolean;
    refundedAmount: string;
    rejected: boolean;
    reservationFee: string;
    reviewComment: string;
    reviewCompleted: boolean;
    salesTax: string;
    standId: string;
    standName: string;
    timeslot: TimeSlot;
    timestamp: number;
    token: string;
    totalAmount: string;
    username: string;
    pickUpDateTimeStr: string;
    isSelected: boolean;
    rejection_reason: string;
    cancelled: string;

    constructor(x: IReservation) {
        this.accepted = x.accepted;
        this.completed = x.completed;
        this.confidence = x.confidence;
        this.confirmed = x.confirmed;
        this.currency = x.currency;
        this.documentId = x.documentId;
        this.email = x.email;
        this.fcmToken = x.fcmToken;
        this.items = x.items;
        this.markForRefund = x.markForRefund;
        this.markForReview = x.markForReview;
        this.password = x.password;
        this.pickUpDateTime = x.pickUpDateTime;
        this.refundCompleted = x.refundCompleted;
        this.refundReviewComment = x.refundReviewComment;
        this.refunded = x.refunded;
        this.refundedAmount = x.refundedAmount;
        this.rejected = x.rejected;
        this.reservationFee = x.reservationFee;
        this.reviewComment = x.reviewComment;
        this.reviewCompleted = x.reviewCompleted;
        this.salesTax = x.salesTax;
        this.standId = x.standId;
        this.standName = x.standName;
        this.timeslot = x.timeslot;
        this.timestamp = x.timestamp;
        this.token = x.token;
        this.totalAmount = x.totalAmount;
        this.username = x.username;
        this.pickUpDateTimeStr = x.pickUpDateTimeStr;
        this.isSelected = x.isSelected;
        this.rejection_reason = x.rejection_reason;
        this.cancelled = x.cancelled;
    }

    static fromFirestore(
        doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>
    ): Reservation | null {
        const data = doc.data();

        if (!data) return null;
        let pickUpDateTimeStr = `${moment(data['pickup_date_time']).format('ddd')}, ${moment(
            data['pickup_date_time']
        ).format('MMM')} ${moment(data['pickup_date_time']).format('D')}`;

        return new Reservation({
            accepted: data['accepted'] ?? false,
            completed: data['completed'] ?? false,
            confidence: data['confidence'] ?? '',
            confirmed: data['confirmed'] ?? false,
            currency: data['currency'] ?? '',
            documentId: doc.id,
            email: data['user_email'] ?? '',
            fcmToken: data['fcm_token'] ?? '',
            items: Item.fromListMap(data['items']),
            markForRefund: data['markForRefund'] ?? false,
            markForReview: data['markForReview'] ?? false,
            password: data['password'] ?? '',
            pickUpDateTime: data['pickup_date_time'] ?? '',
            pickUpDateTimeStr: pickUpDateTimeStr,
            refundCompleted: data['refundCompleted'] ?? false,
            refundReviewComment: data['refundReviewComment'] ?? '',
            refunded: data['refunded'] ?? false,
            refundedAmount: data['refundedAmount'] ?? '',
            rejected: data['rejected'] ?? false,
            reservationFee: data['reservationFee'] ?? '',
            reviewComment: data['reviewComment'] ?? '',
            reviewCompleted: data['reviewCompleted'] ?? '',
            salesTax: data['salesTax'] ?? '',
            standId: data['stand_id'] ?? '',
            standName: data['stand_name'] ?? '',
            timeslot: TimeSlot.fromMap(data['timeslot']),
            timestamp: data['timestamp'] ?? 0,
            token: data['token'] ?? '',
            totalAmount: data['total_amount'] ?? '',
            username: data['user_name'] ?? '',
            isSelected: false,
            rejection_reason: data['rejection_reason'],
            cancelled: data['cancelled'],
        });
    }
}

export class TimeSlot implements ITimeSlot {
    timeslotString: string;
    timeslotMillisecondsSinceEpoch: number;

    constructor(x: ITimeSlot) {
        this.timeslotMillisecondsSinceEpoch = x.timeslotMillisecondsSinceEpoch;
        this.timeslotString = x.timeslotString;
    }

    static fromMap(map: Record<string, any>): TimeSlot {
        if (!map) return new TimeSlot({ timeslotMillisecondsSinceEpoch: 0, timeslotString: '' });

        return new TimeSlot({
            timeslotMillisecondsSinceEpoch: map['timeslotMillisecondsSinceEpoch'] ?? 0,
            timeslotString: map['timeslotString'] ?? '',
        });
    }
}
export class Item implements IItem {
    name: string;
    baitType: string;
    quantity: number;
    pricing: Pricing;

    constructor(x: IItem) {
        this.baitType = x.baitType;
        this.pricing = x.pricing;
        this.name = x.name;
        this.quantity = x.quantity;
    }

    static fromMap(map: Record<string, any>): Item | null {
        if (!map) return null;

        return new Item({
            name: map['product'] ?? '',
            baitType: map['bait_type'] ?? '',
            quantity: map['quantity'] ?? 0,
            pricing: Pricing.fromMap(map['pricing']),
        });
    }

    static fromListMap(map: Record<string, any>[]): Item[] {
        let items: Item[] = [];

        if (map) {
            map.forEach((x) => {
                items.push({
                    name: x['product'] ?? '',
                    baitType: x['bait_type'] ?? '',
                    quantity: x['quantity'] ?? 0,
                    pricing: Pricing.fromMap(x['pricing']),
                });
            });
        }

        return items;
    }

    static toJson(items: Item[]): string {
        let result = '';

        items.forEach((i) => {
            const val =
                i.quantity.toString() +
                'x ' +
                i.name +
                ' (' +
                i.pricing.price.toString() +
                ' ' +
                i.pricing.currency +
                '/' +
                i.pricing.unit.toString() +
                ') ';

            result += val;
        });
        return result;
    }
}

interface IReservation {
    email: string;
    username: string;
    totalAmount: string;
    timestamp: number;
    token: string;
    documentId: string;
    accepted: boolean;
    confirmed: boolean;
    rejected: boolean;
    completed: boolean;
    password: string;
    currency: string;
    pickUpDateTime: string;
    pickUpDateTimeStr: string;
    fcmToken: string;
    standName: string;
    confidence: string;
    reservationFee: string;
    markForRefund: boolean;
    refundCompleted: boolean;
    refunded: boolean;
    refundReviewComment: string;
    refundedAmount: string;
    salesTax: string;
    markForReview: boolean;
    reviewCompleted: boolean;
    reviewComment: string;
    standId: string;
    items: IItem[];
    timeslot: TimeSlot;
    isSelected: boolean;
    rejection_reason: string;
    cancelled: string;
}

interface ITimeSlot {
    timeslotString: string;
    timeslotMillisecondsSinceEpoch: number;
}

interface IItem {
    name: string;
    baitType: string;
    quantity: number;
    pricing: Pricing;
}
