import * as React from "react";
import { Grid, Card, Divider, makeStyles, Theme } from "@material-ui/core";

import ZReservationCard from "../../components/ZReservationCard";
import { AdminContext } from "../../models_context/AdminContext";
import { Reservation } from "../../models/reservation";

import ZDialogForm from "../../components/ZDialogForm";
import ZDialogFormReservation, {
  ResevationProgress,
} from "../../components/ZDialogFormReservation";

interface Props {}

interface resetvationStates {
  toAcceptReservations: Reservation[];
  toConfirmReservations: Reservation[];
  toDeliverReservations: Reservation[];
  toHistoryReservations: Reservation[];
}

const DashboardReservationsPage: React.FC<Props> = () => {
  const { state, dispatch } = React.useContext(AdminContext);
  const [openDrawerReservation, setOpenDrawerReservation] = React.useState(
    false
  );
  const [selectedReservation, setSelectedReservation] = React.useState<
    Reservation
  >();
  const [resevationProgress, setReservationProgress] = React.useState<
    ResevationProgress
  >(ResevationProgress.accept);
  const [reservationStates, setReservationStates] = React.useState<
    resetvationStates
  >({
    toAcceptReservations: [],
    toConfirmReservations: [],
    toDeliverReservations: [],
    toHistoryReservations: [],
  });

  const classes = useStyles();

  React.useEffect(() => {
    reservationStates.toAcceptReservations = [];
    reservationStates.toConfirmReservations = [];
    reservationStates.toDeliverReservations = [];
    reservationStates.toHistoryReservations = [];

    state.reservations.forEach((x) => {
      if (
        !x.accepted &&
        !x.rejected &&
        !x.confirmed &&
        x.timeslot.timeslotMillisecondsSinceEpoch +
          Date.parse(x.pickUpDateTime) >
          Date.now()
      ) {
        reservationStates.toAcceptReservations.push(x);
      }
      if (x.accepted && !x.rejected && !x.confirmed) {
        reservationStates.toConfirmReservations.push(x);
      }
      if (
        x.confirmed === true &&
        x.completed === false &&
        x.rejected === false
      ) {
        reservationStates.toDeliverReservations.push(x);
      }
      if (x.completed === true || x.rejected === true) {
        reservationStates.toHistoryReservations.push(x);
      }
      setReservationStates({ ...reservationStates });
    });

    // console.log('Accepted', reservationStates.toAcceptReservations);
    // console.log('Confirm', reservationStates.toConfirmReservations);
    // console.log('Deliver', reservationStates.toDeliverReservations);
    // console.log('Hisory', reservationStates.toHistoryReservations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.reservations]);
  return (
    <>
      <ZDialogFormReservation
        reservation={selectedReservation}
        reservationProgress={resevationProgress}
        open={openDrawerReservation}
        title=""
        openDialogCallback={setOpenDrawerReservation}
        message={<></>}
      ></ZDialogFormReservation>

      {/* <ZDrawerReservation
                open={openDrawerReservation}
                setOpenDrawerReservation={setOpenDrawerReservation}
                reservation={selectedReservation}
                reservationProgress={resevationProgress}
            /> */}

      <Card className={classes.container}>
        <Grid container className={classes.buttonBar}>
          <Grid container item xs={3}>
            <div>Accept</div>
          </Grid>
          <Grid container item xs={3}>
            <div>Confirm</div>
          </Grid>
          <Grid container item xs={3}>
            <div>Deliver</div>
          </Grid>
          <Grid container item xs={3}>
            <div>History</div>
          </Grid>
        </Grid>
        <Divider className={classes.divider} />

        <Grid
          container
          className={classes.scrollContainer}
          alignItems="flex-start"
        >
          {/* NOTE Accept Resevations */}
          <Grid container item xs={3}>
            {reservationStates.toAcceptReservations.map((x, index) => (
              <ZReservationCard
                onClick={() => {
                  setSelectedReservation(x);
                  setOpenDrawerReservation(true);
                  setReservationProgress(ResevationProgress.accept);
                }}
                reservation={x}
                key={index}
              />
            ))}
            <div className={classes.scrollContainerPadding} />
          </Grid>

          {/* NOTE confirm Resevations */}
          <Grid container item xs={3}>
            {reservationStates.toConfirmReservations.map((x, index) => (
              <ZReservationCard
                onClick={() => {
                  setSelectedReservation(x);
                  setOpenDrawerReservation(true);
                  setReservationProgress(ResevationProgress.confirm);
                }}
                reservation={x}
                key={index}
              />
            ))}
            <div className={classes.scrollContainerPadding} />
          </Grid>

          {/* NOTE Deliver Resevations */}
          <Grid container item xs={3}>
            {reservationStates.toDeliverReservations.map((x, index) => (
              <ZReservationCard
                onClick={() => {
                  setSelectedReservation(x);
                  setOpenDrawerReservation(true);
                  setReservationProgress(ResevationProgress.deliver);
                }}
                reservation={x}
                key={index}
              />
            ))}
            <div className={classes.scrollContainerPadding} />
          </Grid>

          {/* NOTE History Resevations */}
          <Grid container item xs={3}>
            {reservationStates.toHistoryReservations.map((x, index) => (
              <ZReservationCard
                onClick={() => {
                  setSelectedReservation(x);
                  setOpenDrawerReservation(true);
                  setReservationProgress(ResevationProgress.history);
                }}
                reservation={x}
                key={index}
              />
            ))}
            <div className={classes.scrollContainerPadding} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: "1400px",
    height: "780px",
    margin: "auto",
  },

  divider: {
    margin: "25px 0px",
  },

  buttonBar: {
    marginTop: "20px",
    "& div": {
      paddingLeft: "5px",
      fontSize: "16px",
      fontWeight: "600",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.19",
      letterSpacing: "normal",
      textAlign: "left",
      color: "#000000",
    },
  },

  scrollContainer: {
    overflow: "scroll",
    height: "100%",
  },
  scrollContainerPadding: {
    paddingBottom: "80px",
  },
}));

DashboardReservationsPage.defaultProps = {};

export default DashboardReservationsPage;
