import * as React from "react";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Grid, Divider, Card } from "@material-ui/core";

import { Report } from "../models/report";
const moment = require("moment");

interface Props {
  report: Report;

  onClick: () => void;
}

const ZReportCard: React.FC<Props> = ({ report, onClick }) => {
  return (
    <div css={cssStyles} onClick={onClick}>
      <Grid container className="card">
        <Grid className="report-photo">
          <img
            src={report.photo === "" ? "/images/no_image.png" : report.photo}
            alt=""
          />
        </Grid>
        <Grid>
          <div className="title">{report.title}</div>
          <div className="description">{report.description}</div>
          <div className="time">{report.timestampStr}</div>
        </Grid>
      </Grid>
    </div>
  );
};

ZReportCard.defaultProps = {};

const cssStyles = css`
  background-color: #f9f9f9;
  border: solid 1px rgba(112, 112, 112, 0.14);
  border-radius: 5px;
  margin: 0px 10px;
  margin-bottom: 15px;
  width: 98.75%;

  :hover {
    cursor: pointer;
  }

  .report-photo {
    img {
      height: 180px;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }

  .title {
    margin: 10px;
    font-weight: 700;
  }
  .description {
    margin: 10px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }

  .time {
    margin: 10px;
    font-weight: 500;
  }
`;

export default ZReportCard;
