import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyDU1t4LC6bXB98m6WTuv0xVC84xr2ULU2M',
  authDomain: 'baitfinder-94065.firebaseapp.com',
  databaseURL: 'https://baitfinder-94065.firebaseio.com',
  projectId: 'baitfinder-94065',
  storageBucket: 'baitfinder-94065.appspot.com',
  messagingSenderId: '718993479189',
  appId: '1:718993479189:web:58300a779c4843dc50a01c',
  measurementId: 'G-NXK8RGY3M6',
};
if (!firebase.apps.length) firebase.initializeApp(config);

firebase.analytics();

const db = firebase.firestore();

const auth = firebase.auth();

export { firebase, db, auth };
