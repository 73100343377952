import * as React from "react";
import { Grid } from "@material-ui/core";
import ZText from "../../components/ZText";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import ResponsiveCarouselNext from "../../components/ZResponsiveCarouselNext";

interface Props {}

const GuideResevationPage: React.FC<Props> = () => {
  return (
    <div>
      <Grid css={cssStyles}>
        <ZText heading> Reservations</ZText>
        <ZText>
          Three things have the ability to destroy a day of fishing. 1. Weather;
          2. Boat/Trailer Failure; 3. Bait Availability. There are hundreds of
          apps which help you predict the weather. With proper maintenance,
          boat/trailer failures are exceptionally rare. BaitFinder partnering
          with Bait Shops we have the ability to solve the challenge of Bait
          Availability.
        </ZText>
        <ZText subheading>Guides</ZText>
        <ZText>
          For guides, bait availability is critical to their livelihood, we have
          made it simple for Bait Shops to allow guides they know to reserve
          their bait, including the ability to charge them differently.
        </ZText>
        <ZText subheading>Anglers</ZText>

        <ZText>
          For Anglers bait is a small part of the cost of a fishing trip,
          especially when traveling from out of town...Truck Gas, Boat Gas,
          Hotel, not to mention valuable vacation time. After talking to
          hundreds of anglers around the country, virtually all of them would be
          happy (THRILLED) to pay a little more to reduce the risk of bait
          availability ruining their fishing trip.
        </ZText>
        <ZText>
          We have designed the entire process with the Bait Shop in mind. We
          have addressed concerns like "What if all the bait dies", "What if my
          bait supplier does not show up", "What if the Guide/Angler does not
          show up"....and with feedback from dozens of Bait Shops...all the
          other "What if's" have been addressed.
        </ZText>

        <ZText subheading textAlign="center">
          Step 1: Onboard with Stripe
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsStripe}
        ></ResponsiveCarouselNext>

        <ZText subheading textAlign="center">
          Step 2: Enable Reservations
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsEnableReservation}
        ></ResponsiveCarouselNext>

        <ZText subheading textAlign="center">
          Step 2: Processing Reservations
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsProcessReservation}
        ></ResponsiveCarouselNext>
      </Grid>
    </div>
  );
};
const CarouselItemsStripe: ICarouselItem[] = [
  {
    body:
      "1. Stripe is the largest processor of credit cards in the world.  They have a simple intuitive way to create an account and enable BaitFinder to deposit money directly in your account.",
    image: "/images/stripe1.png",
  },
  {
    body:
      "2. On the Info tab of the admin app, click Link, this launches a Stripe powered wizard, which will ask you a few simple questions, and either bank account information or debit card so BaitFinder can directly deposit money in your account.",
    image: "/images/stripe2.png",
  },
];
const CarouselItemsEnableReservation: ICarouselItem[] = [
  {
    body: "1. Enable Guide & User Reservation",
    image: "/images/enable_reservation1.png",
  },
  {
    body: "2. On the bait tab, select the pencil by a bait",
    image: "/images/enable_reservation2.png",
  },
  {
    body: "3. Choose Edit Availability",
    image: "/images/enable_reservation3.png",
  },
  {
    body: "4. Move the toggle to the right to enable reservations",
    image: "/images/enable_reservation4.png",
  },
  {
    body:
      "5. The Angler can tell if you are accepting reservations by the R by a bait",
    image: "/images/enable_reservation5.png",
  },
];
const CarouselItemsProcessReservation: ICarouselItem[] = [
  {
    body:
      "1. Angler Submits...the Angler can cancel until reservation is confirmed.",
    image: "/images/process_reservation1.png",
  },
  {
    body: "2. A reservation comes in",
    image: "/images/process_reservation2.png",
  },
  {
    body: "3. Bait Shop can Accept or reject for any reason",
    image: "/images/process_reservation3.png",
  },
  {
    body: "4. Bait Shop provides a confidence level",
    image: "/images/process_reservation4.png",
  },
  {
    body: "5.  Bait Shop can reject (and provide Angler with reason)",
    image: "/images/process_reservation5.png",
  },
  {
    body:
      "6. Once confirmed Angler can no longer cancel, they can request a refund, but that is up to the Bait Shop.",
    image: "/images/process_reservation6.png",
  },
  {
    body:
      "7. Until delivery, there is no risk and no charge to the bait shop. After delivery, the Stripe Fees are not refundable",
    image: "/images/process_reservation7.png",
  },
];
interface ICarouselItem {
  image: string;
  body: string;
}

const cssStyles = css`
  max-width: 720px;
  margin: auto;
`;

GuideResevationPage.defaultProps = {};

export default GuideResevationPage;
