import * as React from "react";

import { Controller } from "react-hook-form";
import { FormControlLabel, Checkbox } from "@material-ui/core";

interface Props {
  label: string;
  defaultValue: boolean;
  className?: string;
  control: any;
  name: string;
}

const ZCheckBoxTile: React.FC<Props> = ({
  label,
  defaultValue,
  className,
  control,
  name,
}) => {
  return (
    <div key={name} className={className}>
      <Controller
        as={
          <FormControlLabel
            control={<Checkbox color="primary" />}
            label={label}
          />
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </div>
  );
};

ZCheckBoxTile.defaultProps = {};

export default ZCheckBoxTile;
