import * as React from "react";
import {
  Grid,
  Button,
  Card,
  Divider,
  Menu,
  Avatar,
  Icon,
  MenuItem,
  Link,
} from "@material-ui/core";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { AdminContext } from "../../models_context/AdminContext";

import { FirestoreService } from "../../models_services/FirestoreService";
import ZReservationCard from "../../components/ZReservationCard";
import ZCardBaitReport from "../../components/ZCardBaitReport";

import { Reservation } from "../../models/reservation";
import { ResevationProgress } from "../../components/ZDialogFormReservation";
import ZDrawerReservation from "../../components/ZDrawerReservation";

interface Props {}

const DashboardReportsPage: React.FC<Props> = () => {
  const { state, dispatch } = React.useContext(AdminContext);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [reportType, setReportType] = React.useState<string>("Bait Demand");
  const [activebuttonDate, setActiveButtonDate] = React.useState<string>(
    "7 Days"
  );
  const [openDrawerReservation, setOpenDrawerReservation] = React.useState(
    false
  );
  const [selectedReservation, setSelectedReservation] = React.useState<
    Reservation
  >();
  const [resevationProgress, setReservationProgress] = React.useState<
    ResevationProgress
  >(ResevationProgress.accept);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PeriodButtons = () => {
    return (
      <>
        <Button
          variant="outlined"
          size="small"
          className={`button-date ${
            activebuttonDate === "1 Day" ? "button-date__active" : ""
          }`}
          onClick={() => {
            setActiveButtonDate("1 Day");
          }}
        >
          1 day
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={`button-date ${
            activebuttonDate === "7 Days" ? "button-date__active" : ""
          }`}
          onClick={() => {
            setActiveButtonDate("7 Days");
          }}
        >
          7 days
        </Button>
        <Button
          variant="outlined"
          size="small"
          className={`button-date ${
            activebuttonDate === "30 Days" ? "button-date__active" : ""
          }`}
          onClick={() => {
            setActiveButtonDate("30 Days");
          }}
        >
          30 days
        </Button>
      </>
    );
  };

  return (
    <>
      <ZDrawerReservation
        open={openDrawerReservation}
        setOpenDrawerReservation={setOpenDrawerReservation}
        reservation={selectedReservation}
        reservationProgress={resevationProgress}
      />

      <div css={cssStyles}>
        <Card className="paper">
          <Grid
            container
            alignItems="center"
            justify="space-between"
            className="heading"
          >
            <div className="title">Reports</div>

            <div>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                {reportType}
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    setReportType("Bait Demand");
                    setAnchorEl(null);
                  }}
                >
                  Bait Demand
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setReportType("Upcoming Deliveries");
                    setAnchorEl(null);
                  }}
                >
                  Upcoming Deliveries
                </MenuItem>
              </Menu>
              <PeriodButtons />
            </div>
          </Grid>

          <Divider className="divider" />

          {reportType === "Upcoming Deliveries" && (
            <Grid container className="items-container" alignItems="flex-start">
              <Grid container item xs={12}>
                {state.reportDeliveries.map((x, index) => (
                  <Grid item xs={3} key={index}>
                    <div className="reservation-card">
                      <ZReservationCard
                        onClick={() => {
                          setSelectedReservation(x);
                          setOpenDrawerReservation(true);
                          setReservationProgress(ResevationProgress.deliver);
                        }}
                        reservation={x}
                        key={index}
                      />
                    </div>
                  </Grid>
                ))}
                <div className="item-container-padding" />
              </Grid>
            </Grid>
          )}

          {reportType === "Bait Demand" && (
            <Grid container className="items-container" alignItems="flex-start">
              <Grid container item xs={12}>
                {state.reportBaits.map((x, index) => (
                  <Grid item xs={6} key={index}>
                    <div className="reservation-card">
                      <ZCardBaitReport
                        onClick={() => {}}
                        baitReport={x}
                        key={index}
                      />
                    </div>
                  </Grid>
                ))}

                <div className="item-container-padding" />
              </Grid>
            </Grid>
          )}
        </Card>
      </div>
    </>
  );
};

const cssStyles = css`
  .paper {
    width: 1400px;
    height: 780px;
    margin: auto;
  }

  .reservation-card {
    width: 93.5%;
  }

  .button-date {
    margin: 0px 5px;
  }

  .button-date__active {
    background-color: aquamarine;
  }

  .title {
    font-size: 20px;
    margin-left: 10px;
  }

  .heading {
    height: 60px;
    padding-left: 10px;
    padding-right: 20px;
  }

  .divider {
    margin-bottom: 20px;
    height: 0.5px;
    border: solid 0.5px rgba(112, 112, 112, 0.05);
  }
`;

DashboardReportsPage.defaultProps = {};

export default DashboardReportsPage;
