import * as React from "react";
import {
  Drawer,
  Grid,
  Card,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Reservation } from "../models/reservation";
import { firebase, db } from "../firebase";

export enum ResevationProgress {
  accept,
  confirm,
  deliver,
  history,
}

interface Props {
  open: boolean;
  setOpenDrawerReservation: React.Dispatch<React.SetStateAction<boolean>>;
  reservation: Reservation | null | undefined;
  reservationProgress: ResevationProgress;
}

const ZDrawerReservation: React.FC<Props> = ({
  open,
  setOpenDrawerReservation,
  reservation,
  reservationProgress,
}) => {
  async function reservationAccept(confidence: string) {
    db.collection("v2reservations").doc(reservation?.documentId).update({
      accept: true,
      confidence: confidence,
    });
  }
  async function reservationConfirm() {
    db.collection("v2reservations").doc(reservation?.documentId).update({
      confirmed: true,
    });
  }
  async function reservationReject(reason: string) {
    db.collection("v2reservations").doc(reservation?.documentId).update({
      refunded: true,
      rejected: true,
      rejection_reason: reason,
    });
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setOpenDrawerReservation(false);
      }}
      css={cssStyles}
    >
      <div className="drawer">
        {reservation && (
          <div>
            <div className="page-title">{reservation.username}</div>
            <div className="email">{reservation.email}</div>
            <div className="order-id-title">Order Id</div>
            <div className="order-id">#{reservation.documentId}</div>

            {reservationProgress === ResevationProgress.deliver && (
              <Grid container justify="space-between">
                <Grid item>Passcode</Grid>
                <Grid item>
                  <div>{reservation.password}</div>
                </Grid>
              </Grid>
            )}
            <Divider className="divider" />

            <Grid className="pick-up" container justify="space-between">
              <div>pickup time</div>
              <div>
                <div>{reservation.pickUpDateTimeStr}</div>
                <div>{reservation.timeslot.timeslotString}</div>
              </div>
            </Grid>

            <div className="baits">Baits</div>
            <div>
              {reservation.items.map((x, index) => (
                <div key={index}>
                  <Grid container justify="space-between" alignItems="center">
                    <Grid item>
                      <div>
                        {x.name} {x.baitType}
                      </div>
                      <div>
                        {x.quantity} @ {x.pricing.currency} {x.pricing.price}{" "}
                        {x.pricing.unit}
                      </div>
                    </Grid>
                    <Grid>${x.quantity * x.pricing.price}</Grid>
                  </Grid>
                  <Divider className="divider" />
                </div>
              ))}
            </div>

            <Grid container justify="space-between">
              <Grid item>Sales Tax</Grid>
              <Grid item>
                <div>${reservation.salesTax}</div>
              </Grid>
            </Grid>

            <Divider className="divider" />

            <Grid container justify="space-between">
              <Grid item>Reservation Fee</Grid>
              <Grid item>${reservation.reservationFee}</Grid>
            </Grid>

            <Divider className="divider" />

            <Grid container justify="space-between">
              <Grid item>Total Amount</Grid>
              <Grid item>${reservation.totalAmount}</Grid>
            </Grid>

            <Divider className="divider" />

            {/* {reservationProgress === ResevationProgress.accept && (
                            <div>
                                <div className='confidence-level'>Accept with confidence level</div>
                                <Grid container className='button-grid'>
                                    <Grid item xs={3}>
                                        <Button
                                            fullWidth
                                            variant='outlined'
                                            color='primary'
                                            className='accept95up'>
                                            95%+
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            fullWidth
                                            variant='outlined'
                                            color='primary'
                                            className='accept75up'>
                                            75-95%
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            fullWidth
                                            variant='outlined'
                                            color='primary'
                                            className='accept50up'>
                                            50-75%
                                        </Button>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Button
                                            fullWidth
                                            variant='outlined'
                                            color='primary'
                                            className='accept0up'>
                                            50%
                                        </Button>
                                    </Grid>
                                </Grid>
                            </div>
                        )} */}

            {/* {reservationProgress === ResevationProgress.confirm && (
                            <Button
                                fullWidth
                                variant='outlined'
                                color='primary'
                                className='confirm-button'>
                                Confirm Order
                            </Button>
                        )}

                        {reservationProgress === ResevationProgress.deliver && (
                            <div>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color='secondary'
                                    className='confirm-button'>
                                    Refund Order(Total)
                                </Button>
                                <Button fullWidth variant='contained' color='primary'>
                                    Complete Order
                                </Button>
                            </div>
                        )}

                        {reservationProgress === ResevationProgress.accept && (
                            <Button fullWidth variant='contained' color='secondary'>
                                Reject Order
                            </Button>
                        )}

                        {reservationProgress === ResevationProgress.confirm && (
                            <Button fullWidth variant='contained' color='secondary'>
                                Reject Order
                            </Button>
                        )} */}
          </div>
        )}
      </div>
    </Drawer>
  );
};

const cssStyles = css`
  .drawer {
    width: 380px;
    height: 100%;
    padding: 10px;
    overflow: auto;
  }
  .page-title {
    margin-top: 30px;
    color: #1b3a57;
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
  }

  .confidence-level {
    margin-top: 40px;
  }
  .button-grid {
    margin-top: 10px;
    margin-bottom: 40px;
  }

  .confirm-button {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  .email {
  }

  .baits {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .order-id-title {
    margin-top: 20px;
  }

  .pick-up {
    margin-top: 20px;
  }

  .order-id {
    font-weight: 700;
  }

  .divider {
    margin: 20px 0px;
  }

  .MuiTypography-body1 {
    font-size: 0.95rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

  .submit-button {
    margin-top: 20px;
  }

  .form {
    .text-field {
    }

    .MuiButton-outlinedPrimary {
      color: #3f51b5;
      border: 1px solid rgba(63, 81, 181, 0.5);
      margin-top: 20px;
    }
  }
`;

ZDrawerReservation.defaultProps = {};

export default ZDrawerReservation;
