export class Bait implements IBait {
  app: string;
  icon: string;
  name: string;
  salt: boolean;
  documentId: string;

  constructor(x: IBait) {
    this.app = x.app;
    this.icon = x.icon;
    this.name = x.name;
    this.salt = x.salt;
    this.documentId = x.documentId;
  }

  static fromFirestore(doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>): Bait | null {
    const data = doc.data();

    if (!data) return null;

    return new Bait({
      documentId: doc.id,
      app: data['app'] ?? '',
      icon: data['icon'] ?? '',
      name: data['name'] ?? '',
      salt: data['salt'] ?? false,
    });
  }

  toJson(x: Bait): Record<string, any> {
    return {
      app: x.app,
      icon: x.icon,
      name: x.name,
      salt: x.salt,
    };
  }
}

interface IBait {
  app: string;
  icon: string;
  name: string;
  salt: boolean;
  documentId: string;
}
