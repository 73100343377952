import * as React from "react";
import { Grid, Divider } from "@material-ui/core";
import ZText from "../../components/ZText";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import ResponsiveCarouselNext from "../../components/ZResponsiveCarouselNext";

interface Props {}

const GuideRefundsPage: React.FC<Props> = () => {
  return (
    <div>
      <Grid css={cssStyles}>
        <ZText heading> Canceling Order and Refunding</ZText>
        <ZText>
          This section details exactly what happens in each step of the
          reservation process, what can be cancelled, refunded and what happens.
        </ZText>

        <ZText subheading textAlign="center">
          Canceling Before Confirmation
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsBeforeConfirm}
        ></ResponsiveCarouselNext>

        <Divider className="divider" />

        <ZText subheading textAlign="center">
          Canceling After Bait Shop Confirms
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsAfterConfirm}
        ></ResponsiveCarouselNext>

        <Divider className="divider" />

        <ZText subheading textAlign="center">
          Refunding after delivery and partial refunds
        </ZText>
        <ZText textAlign="center">
          After an order is delivered, a Bait Shop will still have the
          opportunity to do a full or partial refund for 24 hours.
        </ZText>
        <ZText textAlign="center">
          This is used by some Bait Shops where they allow Guides to get a
          credit for unused bait, also it is used if a Angler shows up and a
          Bait Shop only has 4 dozen of something and the order was for 8
          dozen....they can do a partial refund
        </ZText>
        <ZText textAlign="center">
          After an order is delivered the Process Fees are no longer refundable.
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsAfterDelivery}
        ></ResponsiveCarouselNext>
      </Grid>
    </div>
  );
};

const CarouselItemsBeforeConfirm: ICarouselItem[] = [
  {
    body:
      "1. Both Angler and Bait Shop can cancel a reservation until a reservation is confirmed",
    image: "/images/refund_before_confirmation1.png",
  },
  {
    body:
      "2. Angler simply hits cancel Bait Shop hits Reject Bait Shop is given opportunity to provide reason",
    image: "/images/refund_before_confirmation2.png",
  },
  {
    body:
      "3. There are no Fees to the bait shop or the angler.   The hold on the Anglers account is removed immediately ",
    image: "/images/refund_before_confirmation3.png",
  },
];
const CarouselItemsAfterConfirm: ICarouselItem[] = [
  {
    body:
      "1. After a Bait Shop confirms a reservation, the Angler can no longer cancel, they can request a refund. Before an order is completed, a Bait Shop can only do a full refund.",
    image: "/images/refund_after_confirmation1.png",
  },
  {
    body:
      "2. In this case there are no charges to the Bait Shop or to the Angler.   The Authorization is released and the transaction is complete.",
    image: "/images/refund_after_confirmation2.png",
  },
  {
    body:
      "3. A bait shop can choose to reject the refund request, typically this is when the Bait Shop placed a special order or specifically captured the bait for this order.",
    image: "/images/refund_after_confirmation3.png",
  },
];
const CarouselItemsAfterDelivery: ICarouselItem[] = [
  {
    body: "",
    image: "/images/refund_after_delivery1.png",
  },
  {
    body: "",
    image: "/images/refund_after_delivery2.png",
  },
];
interface ICarouselItem {
  image: string;
  body: string;
}

const cssStyles = css`
  max-width: 720px;
  margin: auto;

  .divider {
    margin-bottom: 30px;
  }
`;

GuideRefundsPage.defaultProps = {};

export default GuideRefundsPage;
