import { db, firebase } from '../firebase';
import { Stand } from '../models/stand';
import { Bait } from '../models/bait';

enum modes {
    live,
    dev,
}

export class FirestoreService {
    /* ------------------------------- NOTE STANDS ------------------------------ */
    /* ------------------------------- NOTE STANDS ------------------------------ */

    static mode: modes = modes.live;

    static limit = FirestoreService.mode === modes.live ? 200 : 30;

    static getStands = async function (email: string) {
        const query = await db.collection('v2market').where('admins', 'array-contains', email).get();
        return query;
    };

    static streamStands = function (
        email: string,
        observer: {
            next?: ((snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void) | undefined;
            error?: ((error: Error) => void) | undefined;
            complete?: (() => void) | undefined;
        }
    ) {
        return db.collection('v2market').where('admins', 'array-contains', email).limit(FirestoreService.limit).onSnapshot(observer);
    };

    static getStand = async function (id: string): Promise<Stand | null> {
        const query = await db.collection('v2market').doc(id).get();
        return Stand.fromFirestore(query);
    };

    static updateStand = async function (id: string, data: any) {
        await db.collection('v2market').doc(id).set(data, { merge: true });
    };

    static getStocks = async function (id: string) {
        const query = await db.collection('v2stocks').where('standId', '==', id).get();

        let data: any[] = [];

        query.docs.forEach((doc) => {
            data.push({ documentId: doc.id, ...doc.data() });
        });
        console.log(data);
        return data;
    };

    /* ------------------------------- NOTE STOCKS ------------------------------ */
    /* ------------------------------- NOTE STOCKS ------------------------------ */

    static streamStocks = function (
        id: string,
        observer: {
            next?: ((snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void) | undefined;
            error?: ((error: Error) => void) | undefined;
            complete?: (() => void) | undefined;
        }
    ) {
        return db
            .collection('v2stocks')
            .where('standId', '==', id)
            .orderBy('stockId', 'asc')
            .limit(FirestoreService.limit)
            .onSnapshot(observer);
    };

    /* ------------------------------- NOTE BAITS ------------------------------- */
    /* ------------------------------- NOTE BAITS ------------------------------- */

    static getBaits = async function () {
        const query = await db.collection('v2product').limit(500).get();

        let baits: Bait[] = [];

        query.docs.forEach((doc) => {
            const bait = Bait.fromFirestore(doc);
            if (bait) baits.push(bait);
        });
        console.log('products', baits);

        return baits;
    };

    static addBait = async function (data: any) {
        await db.collection('v2stocks').add(data);
    };

    static deleteBait = async function (id: string) {
        await db.collection('v2stocks').doc(id).delete();
    };

    static updateBait = async function (id: string, data: any) {
        await db.collection('v2stocks').doc(id).set(data, { merge: true });
    };

    /* ---------------------------- NOTE RESERVATIONS --------------------------- */
    /* ---------------------------- NOTE RESERVATIONS --------------------------- */
    static streamReservations = function (
        standId: string,
        observer: {
            next?: ((snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void) | undefined;
            error?: ((error: Error) => void) | undefined;
            complete?: (() => void) | undefined;
        }
    ) {
        return db
            .collection('v2reservations')
            .where('stand_id', '==', standId)
            .orderBy('timestamp', 'desc')
            .limit(FirestoreService.limit)
            .onSnapshot(observer);
    };

    /* ---------------------------- NOTE Reports --------------------------- */
    /* ---------------------------- NOTE Reports --------------------------- */
    static streamFishingReports = function (
        standId: string,
        observer: {
            next?: ((snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void) | undefined;
            error?: ((error: Error) => void) | undefined;
            complete?: (() => void) | undefined;
        }
    ) {
        return db
            .collection('v2reports')
            .where('standId', '==', standId)
            .orderBy('timestamp', 'desc')
            .limit(FirestoreService.limit)
            .onSnapshot(observer);
    };

    static deleteReport = function (id: string) {
        db.collection('v2reports').doc(id).delete();
    };

    static streamReports = function (
        standId: string,
        observer: {
            next?: ((snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => void) | undefined;
            error?: ((error: Error) => void) | undefined;
            complete?: (() => void) | undefined;
        }
    ) {
        return db
            .collection('v2reservations')
            .where('stand_id', '==', standId)
            .where('confirmed', '==', true)
            .where('completed', '==', false)
            .where('rejected', '==', false)
            .where('pickup_date_time', '>=', new Date().toISOString().substring(0, 5))
            .orderBy('pickup_date_time')
            .orderBy('timestamp', 'desc')
            .limit(FirestoreService.limit)
            .onSnapshot(observer);
    };

    static getDeliveries = async function (standId: string) {
        const reservations: firebase.firestore.DocumentData[] = [];
        const query = await db
            .collection('v2reservations')
            .where('stand_id', '==', standId)
            .where('confirmed', '==', true)
            .where('completed', '==', false)
            .where('rejected', '==', false)
            .orderBy('timestamp', 'asc')
            .limit(FirestoreService.limit)
            .get();

        query.forEach((q) => {
            reservations.push(q.data());
        });

        console.log('Emm Reservation', reservations);
    };
}
