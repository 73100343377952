import * as React from "react";
import {
  Grid,
  Button,
  LinearProgress,
  CircularProgress,
  Paper,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import styled from "@emotion/styled";
import ZText from "../components/ZText";
import { firebase } from "../firebase";

interface Props {}

const cssGrid = css`
  margin-top: 50px;
`;

const cssPaperSuccess = css`
  overflow: hidden;
  padding: 50px;
  height: 350px;
  width: 500px;
  margin: 70px auto;

  .button {
    margin-top: 30px;
  }
  p {
    display: block;
    width: 100%;
    text-align: center;
  }

  img {
    height: 100px;
    display: block;
  }
`;
const StripeSuccess: React.FC<Props> = () => {
  const [isLoading, setIsloading] = React.useState(true);
  const [isStripeAdded, setIsStripeAdded] = React.useState<boolean | null>(
    null
  );
  const [error, setError] = React.useState<any>();

  let history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);
  const stripeCode = searchParams.get("code");
  React.useEffect(() => {
    getStripeId(stripeCode);
  }, [stripeCode]);

  const getStripeId = async (code: any) => {
    const response = await axios.post(
      "https://us-central1-baitfinder-94065.cloudfunctions.net/onStripeEnroll/api/v1/val",
      {
        code,
      }
    );
    // const response = await axios.post(
    //     'http://localhost:5000/baitfinder-94065/us-central1/onStripeEnroll/api/v1/val',
    //     {
    //         code
    //     }
    // );

    const { status, userId, error } = response.data;
    setError(error);

    setIsStripeAdded(false);
    if (userId) {
      const firebaseuser = await firebase.auth().currentUser;
      try {
        firebase
          .firestore()
          .collection("v2webSandBox")
          .doc("v2webSandBox")
          .collection("users")
          .doc(firebaseuser?.uid)
          .set({ stripeId: userId }, { merge: true });
        setIsStripeAdded(true);
      } catch (error) {
        setIsStripeAdded(false);
      }
    }
    setIsloading(false);

    console.log({ status, userId, error });
  };

  return (
    <div>
      {isLoading && (
        <Grid container justify="center" css={cssGrid}>
          <ZText>Checking stripe integration</ZText>
          <Grid container justify="center" item xs={12}>
            <CircularProgress />
          </Grid>
        </Grid>
      )}
      {!isLoading && isStripeAdded && (
        <Paper css={cssPaperSuccess}>
          <Grid container justify="center" alignItems="center">
            <Grid item xs={12} container justify="center">
              <img src="/images/success.png" alt="" />
              <p>Stripe integration succesfull</p>
            </Grid>
            <Grid item xs={12} container justify="center" className="button">
              <Button
                type="submit"
                variant="outlined"
                onClick={() => {
                  history.push("/");
                }}
              >
                Return to dashboard
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )}
      {isStripeAdded === false && (
        <Grid container justify="center" css={cssGrid}>
          <ZText>There was an error adding you stripe key</ZText>
          <Grid container justify="center" item xs={12}>
            <ZText>{error}</ZText>
          </Grid>
        </Grid>
      )}

      <Grid container justify="center"></Grid>
    </div>
  );
};

StripeSuccess.defaultProps = {};

export default StripeSuccess;
