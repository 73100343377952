import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Chip, createStyles, Theme, makeStyles } from '@material-ui/core';
import Colors from '../styles/Colors';

interface Props {
  onClick?: () => void;
  className?: any;
  name: string;
}

const ZChip: React.FC<Props> = ({ onClick, name, className }) => {
  return (
    <div css={cssStyles}>
      <Chip label={name} clickable color='primary' onClick={onClick} className={`chip ${className}`} />
    </div>
  );
};

ZChip.defaultProps = {};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: Colors.white,
      height: '100vh'
    },
    sideImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    leftContainer: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },
    rightContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%'
    },
    backButton: {
      position: 'relative'
    },
    backButtonText: {
      top: '30px',
      left: '35px',
      position: 'absolute',
      fontSize: 21,
      fontWeight: 600,
      lineHeight: '35px',
      color: Colors.black,
      cursor: 'pointer'
    }
  })
);

const cssStyles = css`
  .chip {
    margin: 3px 0px;
    width: 100%;
  }
`;

export default ZChip;
