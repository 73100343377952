import * as React from "react";
import {
  Grid,
  makeStyles,
  Theme,
  Container,
  Button,
  Card,
  Typography,
} from "@material-ui/core";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface Props {}

interface PdfFile {
  title: string;
  path: string;
}

const GuideTutorialDownloads: React.FC<Props> = () => {
  const classes = useStyles();

  const [numPages, setNumPages] = React.useState(1);
  const [pageNumber, setPageNumber] = React.useState<number>(1);
  const [pdfFile, setPdfFile] = React.useState<PdfFile>({
    title: "",
    path: "",
  });
  const [selectedPdf, setSelectedPdf] = React.useState<number>(0);

  React.useEffect(() => {
    setPdfFile(PfdFiles[0]);
  }, []);

  function onDocumentLoadSuccess(numPages: any) {
    setNumPages(numPages.numPages);
    setPageNumber(1);
  }

  function changePage(offset: any) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <Container maxWidth="xl">
      <Grid container className={classes.root}>
        <Grid item xs={2}>
          <Typography className={classes.pdfHeading}>Tutorials</Typography>

          {PfdFiles.map((pdf, index) => (
            <Card
              className={`${classes.pdfCard}  ${
                selectedPdf === index ? classes.pdfCardSelected : ""
              }`}
              onClick={() => {
                setPdfFile(pdf);
                setSelectedPdf(index);
              }}
            >
              <Typography>{pdf.title}</Typography>
            </Card>
          ))}
        </Grid>
        <Grid item xs={10} className={classes.documentContainer}>
          <Document file={pdfFile.path} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} height={900} />
          </Document>

          <Grid className={classes.nextContainer}>
            <div>
              Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
            </div>
            <div>
              <Button
                className={classes.nextContainerBtn}
                color="secondary"
                variant="contained"
                disabled={pageNumber <= 1}
                onClick={previousPage}
              >
                Previous
              </Button>

              <Button
                className={classes.nextContainerBtn}
                color="primary"
                variant="contained"
                disabled={pageNumber >= numPages}
                onClick={nextPage}
              >
                Next
              </Button>
            </div>
            <Button
              className={classes.pdfDownloadBtn}
              color="primary"
              variant="contained"
            >
              <a
                className={classes.pdfDownloadBtnALink}
                href={pdfFile.path}
                download={pdfFile.title}
              >
                Download PDF
              </a>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  documentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  nextContainer: {
    marginTop: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  nextContainerBtn: {
    margin: "10px 5px",
  },

  pdfHeading: {
    fontWeight: 600,
    fontSize: "2em",
  },

  pdfCard: {
    margin: "10px 0px",
    padding: "10px",

    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#74b9ff",
    },
  },

  pdfCardSelected: {
    backgroundColor: "#74b9ff",
  },

  pdfDownloadBtn: {
    textDecoration: "none",
    backgroundColor: "#a29bfe",
    color: "white",
  },
  pdfDownloadBtnALink: {
    color: "white",
    textDecoration: "none",
  },
}));

const PfdFiles: PdfFile[] = [
  {
    title: "BaitFinder BaitShop Payment SystemSetup",
    path: "/pdfs/BaitFinderBaitShopPaymentSystemSetup.pdf",
  },
  {
    title: "BaitFinder BaitShop Setup",
    path: "/pdfs/BaitFinderBaitShopSetup.pdf",
  },
];

GuideTutorialDownloads.defaultProps = {};

export default GuideTutorialDownloads;
