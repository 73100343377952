import * as React from "react";
import {
  Grid,
  Button,
  Card,
  Chip,
  Icon,
  Drawer,
  Divider,
} from "@material-ui/core";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { AdminContext } from "../../models_context/AdminContext";
import ZChip from "../../components/ZChip";
import { FirestoreService } from "../../models_services/FirestoreService";
import { useSnackbar, VariantType } from "notistack";
import ZDrawerBait from "../../components/ZDrawerBait";
import ZBiatStandsButtons from "../../components/ZBaitStandsButtons";
import ZDrawerBaitPrice from "../../components/ZDrawerBaitPrice";
import { Stock } from "../../models/stock";
import ZBaitChip from "../../components/ZBaitChip";
import ZBaitCard from "../../components/ZBaitCard";

interface Props {}

const DashboardBaitsPage: React.FC<Props> = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { state, dispatch } = React.useContext(AdminContext);
  const [openDrawerBait, setOpenDrawerBait] = React.useState(false);
  const [openDrawerBaitPrice, setOpenDrawerBaitPrice] = React.useState(false);
  const [stock, setStock] = React.useState<Stock | undefined>();

  return (
    <>
      <ZDrawerBait
        stock={stock}
        open={openDrawerBait}
        setOpenDrawer={setOpenDrawerBait}
        standId={state?.stand?.documentId ?? null}
      />
      <ZDrawerBaitPrice
        stock={stock}
        open={openDrawerBaitPrice}
        setOpenDrawer={setOpenDrawerBaitPrice}
        standId={state?.stand?.documentId ?? null}
      />

      <div css={cssStyles}>
        <Grid item xs={12} container justify="flex-end" className="bait-button">
          <Button
            onClick={() => {
              setStock(undefined);
              setOpenDrawerBait(true);
            }}
            variant="outlined"
          >
            Add Bait
          </Button>
        </Grid>

        {state.stand && (
          <Card className="paper">
            <Grid container className="button-bar">
              <Grid container item xs={6} className="button">
                <div>Salt Water</div>
              </Grid>
              <Grid container item xs={6} className="button">
                <div>Fresh Water</div>
              </Grid>
            </Grid>

            <Divider className="divider-button" />

            <Grid container className="items-container" alignItems="flex-start">
              <Grid container item xs={6} className="item-container-padding">
                {state.stocks.map((stock: Stock, i: number) =>
                  stock.salt ? (
                    <Grid item xs={6} key={i}>
                      <ZBaitCard
                        stock={stock}
                        onClickBait={() => {
                          setStock(stock);
                          setOpenDrawerBait(true);
                        }}
                        onClickPrice={() => {
                          setStock(stock);
                          setOpenDrawerBaitPrice(true);
                        }}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
              <Grid container item xs={6} className="item-container-padding">
                {state.stocks.map((stock: Stock, i: number) =>
                  !stock.salt ? (
                    <Grid item xs={6} key={i}>
                      <ZBaitCard
                        stock={stock}
                        onClickBait={() => {
                          setStock(stock);
                          setOpenDrawerBait(true);
                        }}
                        onClickPrice={() => {
                          setStock(stock);
                          setOpenDrawerBaitPrice(true);
                        }}
                      />
                    </Grid>
                  ) : null
                )}
              </Grid>
            </Grid>
          </Card>
        )}
      </div>
    </>
  );
};

const cssStyles = css`
  .items-container {
    overflow: scroll;
    height: 100%;
    .item-container-padding {
      padding-bottom: 80px;
    }
  }

  .bait-button {
    width: 1400px;
    margin: auto;
    margin-bottom: 10px;
  }
  .paper {
    width: 1400px;
    height: 780px;
    margin: auto;
  }

  .button-bar {
    margin-top: 20px;

    .button {
      padding-left: 10px;
      font-size: 16px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.19;
      letter-spacing: normal;
      text-align: left;
      color: #000000;
    }
  }

  .divider-button {
    margin: 20px 0px;
    height: 1px;
    border: solid 1px rgba(112, 112, 112, 0.05);
  }

  .buttons {
    width: 1200px;
    margin: auto;
    margin-bottom: 10px;
  }

  .btn {
    margin-right: 20px;
  }

  .divider {
    width: 1240px;
    margin: auto;
    margin-bottom: 10px;
  }
`;

DashboardBaitsPage.defaultProps = {};

export default DashboardBaitsPage;
