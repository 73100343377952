import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import NotFoundPage from '../pages/NotFoundPage';
import SignInPage from '../pages/SignInPage';
import DashboardBaitShopsPage from '../pages/dashboards/DashboardSummaryPage';
import { AuthContext } from '../models_context/AuthContext';
import ZNavbar from '../components/ZNavbar';
import StripeSuccess from '../pages/StripeSuccess';
import ZDrawer from '../components/ZDrawer';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import GuideRefundsPage from '../pages/guides/GuideRefundsPage';
import GuideResevationPage from '../pages/guides/GuideReservationPage';
import DashboardFishingReportsPage from '../pages/dashboards/DashboardFishingReportsPage';
import DashboardNotificationsPage from '../pages/dashboards/DashboardNotificationsPage';
import DashboardReservationsPage from '../pages/dashboards/DashboardReservationsPage';
import DashboardSummaryPage from '../pages/dashboards/DashboardSummaryPage';
import DashboardBaitsPage from '../pages/dashboards/DashboardBaitsPage';
import DashboardStripePage from '../pages/dashboards/DashboardStripePage';
import DashboardReportsPage from '../pages/dashboards/DashboardReportsPage';
import GuideServiceReservationsAndRentals from '../pages/guides/GuideServiceReservationsAndRental';
import GuideTutorialDownloads from '../pages/guides/GuideTutorialDownloads';

const cssStyles = css`
  .root {
    display: flex;
  }

  .switch-authenticated {
    margin: auto;
    margin-top: 64px;
    padding: 40px;
    width: calc(100% - 240px);
  }

  .switch-unauthenticated {
    margin: auto;
    width: 100%;
  }
`;
const RouterRoutes = () => {
  const { state } = useContext(AuthContext);
  return (
    <div css={cssStyles}>
      {!state.isloading && (
        <Router>
          {state.isAuthenticated && <ZNavbar />}

          <div className='root'>
            {state.isAuthenticated && <ZDrawer />}
            <div className={`${state.isAuthenticated ? 'switch-authenticated' : 'switch-unauthenticated'}`}>
              <Switch>
                {[
                  !state.isAuthenticated && UnauthenticatedRoutes,
                  state.isAuthenticated && AuthenticatedRoutes,
                  <Route key={1} component={NotFoundPage} />,
                ]}
              </Switch>
            </div>
          </div>
        </Router>
      )}
    </div>
  );
};

const UnauthenticatedRoutes = [
  <Route exact path='/' component={SignInPage} />,
  <Route exact path='/login' component={SignInPage} />,
  <Route path='/dashboard' component={SignInPage} />,
];

const AuthenticatedRoutes = [
  <Route exact path='/' component={DashboardSummaryPage} />,
  <Route path='/dashboard-summary' component={DashboardSummaryPage} />,
  <Route path='/dashboard-baits' component={DashboardBaitsPage} />,
  <Route path='/dashboard-stripe' component={DashboardStripePage} />,
  <Route path='/dashboard-fishing-reports' component={DashboardFishingReportsPage} />,
  <Route path='/dashboard-reports' component={DashboardReportsPage} />,
  <Route path='/dashboard-reservations' component={DashboardReservationsPage} />,
  <Route path='/dashboard-notifications' component={DashboardNotificationsPage} />,
  <Route path='/stripe-success' component={StripeSuccess} />,
  <Route path='/guides-refunds' component={GuideRefundsPage} />,
  <Route path='/guides-reservation' component={GuideResevationPage} />,
  <Route path='/service-rentals' component={GuideServiceReservationsAndRentals} />,
  <Route path='/guides-download' component={GuideTutorialDownloads} />,
];

export default RouterRoutes;
