import * as React from "react";
import {
  Grid,
  Button,
  Card,
  Divider,
  CircularProgress,
  Paper,
} from "@material-ui/core";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { AdminContext } from "../../models_context/AdminContext";
import { firebase, db, auth } from "../../firebase";

import ZText from "../../components/ZText";
import ZBiatStandsButtons from "../../components/ZBaitStandsButtons";

interface Props {}

const DashboardStripePage: React.FC<Props> = () => {
  const [isLoading, setIsloading] = React.useState(true);
  const { state, dispatch } = React.useContext(AdminContext);

  return (
    <div css={cssStyles}>
      <Card className="paper">
        {state.stand?.standStripeKey && (
          <div>
            <Paper className="success">
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} container justify="center">
                  <img src="/images/success.png" alt="" />
                  <p>This baitshop has stripe enabled</p>
                </Grid>
              </Grid>
            </Paper>
          </div>
        )}

        {!state.stand?.standStripeKey && (
          <div>
            <Grid container>
              <Grid container justify="center" item xs={12}>
                <Grid container justify="center" item xs={12}>
                  <ZText>You dont have Stripe enabled</ZText>
                </Grid>
                <Grid container justify="center" item xs={12}>
                  <Button
                    color="primary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://dashboard.stripe.com/express/oauth/authorize?response_type=code&client_id=ca_GBVeYwZpynFnRODV6zaX6GR3g97bawfG&scope=read_write"
                    variant="outlined"
                  >
                    Click to enable stripe
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={5}></Grid>
            </Grid>
          </div>
        )}

        {state.stand?.standStripeKey && (
          <Grid container>
            <Grid container justify="center" item xs={12}>
              <Grid container justify="center" item xs={12}>
                <ZText>Your account has stripe integration</ZText>
              </Grid>
              <Button color="secondary" variant="outlined" onClick={() => {}}>
                Remove Stripe
              </Button>
            </Grid>
            <Grid item xs={5}></Grid>
          </Grid>
        )}
      </Card>
    </div>
  );
};

const cssStyles = css`
  .isloading {
    margin-top: 50px;
  }

  .success {
    overflow: hidden;
    padding: 50px;
    height: 350px;
    width: 500px;
    margin: 70px auto;
    display: flex;
    align-items: center;

    .button {
      margin-top: 30px;
    }
    p {
      display: block;
      width: 100%;
      text-align: center;
    }

    img {
      height: 100px;
      display: block;
    }
  }

  .paper {
    width: 1400px;
    height: 780px;
    padding: 20px;
    margin: auto;
    overflow: auto;
  }

  .buttons {
    width: 1240px;
    margin: auto;
    margin-bottom: 10px;
  }

  .btn {
    margin-right: 20px;
  }

  .divider {
    width: 1240px;
    margin: auto;
    margin-bottom: 10px;
  }

  .btn-active {
    color: blueviolet;
    margin-right: 20px;
  }
`;

DashboardStripePage.defaultProps = {};

export default DashboardStripePage;
