const Colors = {
  white: '#FFFFFF',
  black: '#2D2D2D',
  brandColor: '#707070',
  border: '#3D35D1',
  headingBlue: '#102B5C',
  inputBg: '#F2F2F3',
  inputColor: '#6A5959',
  themeBlue: '#0C60EF',
  themePink: '#E8437E'
};

export default Colors;
