import * as DateFns from 'date-fns';

export class HoursOperating implements IHoursOperating {
  monday: DayAndHours;
  tuesday: DayAndHours;
  wednesday: DayAndHours;
  thursday: DayAndHours;
  friday: DayAndHours;
  saturday: DayAndHours;
  sunday: DayAndHours;

  constructor(x: IHoursOperating) {
    this.friday = x.friday;
    this.monday = x.monday;
    this.saturday = x.saturday;
    this.sunday = x.sunday;
    this.thursday = x.thursday;
    this.tuesday = x.tuesday;
    this.wednesday = x.wednesday;
  }

  static fromMap(map: Record<string, any>): HoursOperating {
    if (!map)
      return new HoursOperating({
        monday: DayAndHours.fromMap('Monday', null),
        tuesday: DayAndHours.fromMap('Tuesday', null),
        wednesday: DayAndHours.fromMap('Wednesday', null),
        thursday: DayAndHours.fromMap('Thursday', null),
        friday: DayAndHours.fromMap('Friday', null),
        saturday: DayAndHours.fromMap('Saturday', null),
        sunday: DayAndHours.fromMap('Sunday', null)
      });

    return new HoursOperating({
      monday: DayAndHours.fromMap('Monday', map['monday']),
      tuesday: DayAndHours.fromMap('Tuesday', map['tuesday']),
      wednesday: DayAndHours.fromMap('Wednesday', map['wednesday']),
      thursday: DayAndHours.fromMap('Thursday', map['thursday']),
      friday: DayAndHours.fromMap('Friday', map['friday']),
      saturday: DayAndHours.fromMap('Saturday', map['saturday']),
      sunday: DayAndHours.fromMap('Sunday', map['sunday'])
    });
  }

  static toJson(hoursOperating: HoursOperating) {
    return {
      monday: {
        timeClose: hoursOperating.monday.timeClose,
        timeOpen: hoursOperating.monday.timeOpen,
        isTimeCloseClosed: hoursOperating.monday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.monday.isTimeOpenClosed
      },
      tuesday: {
        timeClose: hoursOperating.tuesday.timeClose,
        timeOpen: hoursOperating.tuesday.timeOpen,
        isTimeCloseClosed: hoursOperating.tuesday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.tuesday.isTimeOpenClosed
      },
      wednesday: {
        timeClose: hoursOperating.wednesday.timeClose,
        timeOpen: hoursOperating.wednesday.timeOpen,
        isTimeCloseClosed: hoursOperating.wednesday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.wednesday.isTimeOpenClosed
      },
      thursday: {
        timeClose: hoursOperating.thursday.timeClose,
        timeOpen: hoursOperating.thursday.timeOpen,
        isTimeCloseClosed: hoursOperating.thursday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.thursday.isTimeOpenClosed
      },
      friday: {
        timeClose: hoursOperating.friday.timeClose,
        timeOpen: hoursOperating.friday.timeOpen,
        isTimeCloseClosed: hoursOperating.friday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.friday.isTimeOpenClosed
      },
      saturday: {
        timeClose: hoursOperating.saturday.timeClose,
        timeOpen: hoursOperating.saturday.timeOpen,
        isTimeCloseClosed: hoursOperating.saturday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.saturday.isTimeOpenClosed
      },
      sunday: {
        timeClose: hoursOperating.sunday.timeClose,
        timeOpen: hoursOperating.sunday.timeOpen,
        isTimeCloseClosed: hoursOperating.sunday.isTimeCloseClosed,
        isTimeOpenClosed: hoursOperating.sunday.isTimeOpenClosed
      }
    };
  }

  //NOTE Updating Original time
  static toJsonHoursString(hoursOperating: HoursOperating) {
    const mondayOpen = hoursOperating.monday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.monday.timeOpen);
    const mondayClose = hoursOperating.monday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.monday.timeClose);

    const tuesdayOpen = hoursOperating.tuesday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.tuesday.timeOpen);
    const tuesdayClose = hoursOperating.tuesday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.tuesday.timeClose);

    const wednesdayOpen = hoursOperating.wednesday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.wednesday.timeOpen);
    const wednesdayClose = hoursOperating.wednesday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.wednesday.timeClose);

    const thursdayOpen = hoursOperating.thursday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.thursday.timeOpen);
    const thursdayClose = hoursOperating.thursday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.thursday.timeClose);

    const fridayOpen = hoursOperating.friday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.friday.timeOpen);
    const fridayClose = hoursOperating.friday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.friday.timeClose);

    const saturdayOpen = hoursOperating.saturday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.saturday.timeOpen);
    const saturdayClose = hoursOperating.saturday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.saturday.timeClose);

    const sundayOpen = hoursOperating.sunday.isTimeOpenClosed ? 'CLOSED' : calculateTimeString(hoursOperating.sunday.timeOpen);
    const sundayClose = hoursOperating.sunday.isTimeCloseClosed ? 'CLOSED' : calculateTimeString(hoursOperating.sunday.timeClose);

    return {
      monday: {
        open: mondayOpen,
        close: mondayClose
      },
      tuesday: {
        open: tuesdayOpen,
        close: tuesdayClose
      },
      wednesday: {
        open: wednesdayOpen,
        close: wednesdayClose
      },
      thursday: {
        open: thursdayOpen,
        close: thursdayClose
      },
      friday: {
        open: fridayOpen,
        close: fridayClose
      },
      saturday: {
        open: saturdayOpen,
        close: saturdayClose
      },
      sunday: {
        open: sundayOpen,
        close: sundayClose
      }
    };
  }
}

export class DayAndHours implements IDayAndHours {
  day: string;
  timeOpen: Date;
  timeClose: Date;
  isTimeOpenClosed: boolean;
  isTimeCloseClosed: boolean;

  constructor(x: IDayAndHours) {
    this.day = x.day;
    this.isTimeCloseClosed = x.isTimeCloseClosed;
    this.isTimeOpenClosed = x.isTimeOpenClosed;
    this.timeOpen = x.timeOpen;
    this.timeClose = x.timeClose;
  }

  static fromMap(key: string, map: Record<string, any> | null): DayAndHours {
    const dateTimeOpen: Date = DateFns.toDate(Date.parse('Mon May 18 2020 08:00:00 GMT-0500'));
    const dateTimeClose: Date = DateFns.toDate(Date.parse('Wed Oct 22 2223 17:00:00 GMT-0500'));
    if (!map)
      return new DayAndHours({
        day: key,
        timeOpen: dateTimeOpen,
        timeClose: dateTimeClose,
        isTimeCloseClosed: false,
        isTimeOpenClosed: false
      });

    return new DayAndHours({
      day: key,
      timeOpen: map['timeOpen']?.toDate() ?? dateTimeOpen,
      timeClose: map['timeClose']?.toDate() ?? dateTimeClose,
      isTimeCloseClosed: map['isTimeCloseClosed'] ?? false,
      isTimeOpenClosed: map['isTimeOpenClosed'] ?? false
    });
  }
}

interface IDayAndHours {
  day: string;
  timeOpen: Date;
  timeClose: Date;
  isTimeOpenClosed: boolean;
  isTimeCloseClosed: boolean;
}

interface IHoursOperating {
  monday: DayAndHours;
  tuesday: DayAndHours;
  wednesday: DayAndHours;
  thursday: DayAndHours;
  friday: DayAndHours;
  saturday: DayAndHours;
  sunday: DayAndHours;
}

function calculateTimeString(dateTime: Date): string {
  const hour = dateTime.getHours();
  const minutes = dateTime.getMinutes();
  return `${hour === 0 ? 12 : hour > 12 ? hour % 12 : hour}:${minutes < 10 ? 0 : ''}${minutes} ${hour >= 12 ? 'PM' : 'AM'}`;
}
