import * as React from "react";
import {
  Drawer,
  Grid,
  Card,
  Button,
  Divider,
  TextField,
} from "@material-ui/core";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";

import { AdminContext } from "../models_context/AdminContext";

import ZBaitPricingCard from "./ZBaitPricingCard";
import ZTextField from "./ZTextField";
import ZTextFieldDropdown from "./ZTextFieldDropdown";
import { useForm, Controller } from "react-hook-form";
import { FirestoreService } from "../models_services/FirestoreService";

type FormData = {
  currency: string;
  price: string;
  unit: string;
};

interface Props {
  open: boolean;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  stock: any;
  standId: string | null;
}

const ZDrawerBaitPrice: React.FC<Props> = ({
  open,
  setOpenDrawer,
  stock,
  standId,
}) => {
  const [showPriceForm, setPriceShowForm] = React.useState(false);
  const [currency, setCurrency] = React.useState("USD");

  const [selectedPriceObject, setSelectedPriceObject] = React.useState<any>({
    updateType: "ADD",
    index: 0,
    type: "live",
    prices: [],
  });

  const updateCurrency = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrency(event.target.value as string);
  };

  const { errors, handleSubmit, control } = useForm<FormData>({});

  const onSubmit = async (data: FormData) => {
    const _data = {
      currency: currency,
      price: Number.isNaN(parseFloat(data.price)) ? 0 : parseFloat(data.price),
      unit: data.unit,
    };

    if (selectedPriceObject.updateType === "ADD") {
      if (selectedPriceObject.type === "live") {
        if (!stock?.live?.pricing) {
          stock.live.pricing = [];
        }
        stock.live.pricing.push(_data);
      } else if (selectedPriceObject.type === "fresh") {
        if (!stock?.fresh?.pricing) {
          stock.fresh.pricing = [];
        }
        stock.fresh.pricing.push(_data);
      } else {
        if (!stock?.frozen?.pricing) {
          stock.frozen.pricing = [];
        }
        stock.frozen.pricing.push(_data);
      }
    }

    if (selectedPriceObject.updateType === "ADD") {
      selectedPriceObject.prices.push({
        ..._data,
      });
    } else {
      selectedPriceObject.prices[
        selectedPriceObject.index
      ].price = Number.isNaN(parseFloat(data.price))
        ? 0
        : parseFloat(data.price);
      selectedPriceObject.prices[selectedPriceObject.index].unit = data.unit;
      selectedPriceObject.prices[selectedPriceObject.index].currency = currency;
    }

    const firebaseData = {
      [selectedPriceObject.type]: {
        pricing: [...selectedPriceObject.prices],
      },
    };

    FirestoreService.updateBait(stock.documentId, firebaseData);
    setSelectedPriceObject({ ...selectedPriceObject });
    setPriceShowForm(false);
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setOpenDrawer(false);
        setPriceShowForm(false);
      }}
      css={cssStyles}
    >
      {!showPriceForm && (
        <div className="drawer">
          <p className="page-title">Modidy Prices</p>
          <Divider className="title-divider" />

          <ZBaitPricingCard
            setPriceShowForm={setPriceShowForm}
            title="Live"
            type="live"
            stockId={stock?.documentId}
            setSelectedPriceObject={setSelectedPriceObject}
            prices={stock?.live?.pricing ?? []}
          />
          <ZBaitPricingCard
            setPriceShowForm={setPriceShowForm}
            title="Fresh"
            type="fresh"
            stockId={stock?.documentId}
            setSelectedPriceObject={setSelectedPriceObject}
            prices={stock?.fresh?.pricing ?? []}
          />
          <ZBaitPricingCard
            setPriceShowForm={setPriceShowForm}
            title="Frozen"
            type="frozen"
            stockId={stock?.documentId}
            setSelectedPriceObject={setSelectedPriceObject}
            prices={stock?.frozen?.pricing ?? []}
          />
        </div>
      )}
      {showPriceForm && (
        <form key={"form"} onSubmit={handleSubmit(onSubmit)} className="drawer">
          <Grid
            container
            justify="space-between"
            alignItems="center"
            className="form"
          >
            <Grid>
              <p className="page-title">Add/Edit Price</p>
            </Grid>
            <Grid>
              <Button
                onClick={() => setPriceShowForm(false)}
                variant="text"
                color="secondary"
              >
                cancel
              </Button>
            </Grid>
          </Grid>
          <Divider className="title-divider" />
          <ZTextFieldDropdown
            label="Currency"
            OnChange={updateCurrency}
            value={currency}
          />

          <ZTextField
            name="price"
            rules={{ required: "This field is required" }}
            control={control}
            error={errors?.unit ? true : false}
            helperText={errors.unit?.message}
            label="Enter Price"
            defaultValue={
              selectedPriceObject.updateType === "ADD"
                ? ""
                : selectedPriceObject?.prices[selectedPriceObject.index]
                    ?.price ?? ""
            }
            className="text-field"
          ></ZTextField>

          <ZTextField
            name="unit"
            rules={{ required: "This field is required" }}
            control={control}
            error={errors?.unit ? true : false}
            helperText={errors.unit?.message}
            label="Enter unit(lb/kg etc)"
            defaultValue={
              selectedPriceObject.updateType === "ADD"
                ? ""
                : selectedPriceObject?.prices[selectedPriceObject.index]
                    ?.unit ?? ""
            }
            className="text-field"
          ></ZTextField>

          <Button
            variant="outlined"
            color="primary"
            type="submit"
            fullWidth
            className="submit-button"
          >
            Save
          </Button>
        </form>
      )}
    </Drawer>
  );
};

const cssStyles = css`
  .drawer {
    width: 380px;
    height: 100%;
    padding: 10px;
    overflow: auto;
  }
  .page-title {
    color: #1b3a57;
    font-size: 18px;
    text-align: center;
    line-height: 20px;
    font-weight: 700;
  }

  .title-divider {
    margin: 20px 0px;
  }

  .MuiTypography-body1 {
    font-size: 0.95rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.00938em;
  }

  .submit-button {
    margin-top: 20px;
  }

  .form {
    .text-field {
    }

    .MuiButton-outlinedPrimary {
      color: #3f51b5;
      border: 1px solid rgba(63, 81, 181, 0.5);
      margin-top: 20px;
    }
  }
`;

ZDrawerBaitPrice.defaultProps = {};

export default ZDrawerBaitPrice;
