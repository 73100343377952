import * as React from 'react';
import { Grid, Button, Card, Divider } from '@material-ui/core';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import ZReservationCard from '../../components/ZReservationCard';
import { AdminContext } from '../../models_context/AdminContext';
import { Reservation } from '../../models/reservation';

import ZReportCard from '../../components/ZReportCard';
import ZDrawerReport from '../../components/ZDrawerReport';
import { Report } from '../../models/report';

interface Props {}

const DashboardFishingReportsPage: React.FC<Props> = () => {
  const { state, dispatch } = React.useContext(AdminContext);
  const [openDrawerReport, setOpenDrawerReport] = React.useState(false);
  const [selectedReport, setSelectedReport] = React.useState<Report | null>();

  return (
    <>
      <ZDrawerReport open={openDrawerReport} setOpenDrawerReport={setOpenDrawerReport} report={selectedReport} />
      <div css={cssStyles}>
        <Card className='paper'>
          <Grid container justify='flex-end' alignItems='center' className='button-grid'>
            <Button
              variant='outlined'
              color='primary'
              className='botton-new'
              onClick={() => {
                setSelectedReport(null);
                setOpenDrawerReport(true);
              }}
            >
              New Report
            </Button>
          </Grid>

          <Divider className='divider' />

          <Grid container className='items-container'>
            {state.fishingReports.map((x, index) => (
              <Grid container item xs={4} key={index}>
                <ZReportCard
                  onClick={() => {
                    setSelectedReport(x);
                    setOpenDrawerReport(true);
                  }}
                  report={x}
                  key={index}
                />
              </Grid>
            ))}

            <div className='item-container-padding' />
          </Grid>
        </Card>
      </div>
    </>
  );
};

const cssStyles = css`
  .paper {
    width: 1400px;
    height: 780px;
    margin: auto;
  }

  .button-grid {
    height: 60px;
  }

  .botton-new {
    margin-right: 20px;
  }

  .items-container {
    overflow: scroll;
    height: 100%;
    .item-container-padding {
      padding-bottom: 90px;
      width: 100%;
    }
  }

  .divider {
    margin-bottom: 20px;
    height: 0.5px;
    border: solid 0.5px rgba(112, 112, 112, 0.05);
  }
`;

DashboardFishingReportsPage.defaultProps = {};

export default DashboardFishingReportsPage;
