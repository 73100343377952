import * as React from 'react';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Grid, Divider, Button } from '@material-ui/core';

import { BaitReport } from '../models/baitReport';

interface Props {
  baitReport: BaitReport;
  onClick: () => void;
}

const ZCardBaitReport: React.FC<Props> = ({ baitReport, onClick }) => {
  const [activebuttonDate, setActiveButtonDate] = React.useState<string>('All');

  const PeriodButtons = () => {
    return (
      <>
        <Button
          variant='outlined'
          size='small'
          className={`button-date ${activebuttonDate === 'All' ? 'button-date__active' : ''}`}
          onClick={() => {
            setActiveButtonDate('All');
          }}
        >
          All
        </Button>
        <Button
          variant='outlined'
          size='small'
          className={`button-date ${activebuttonDate === 'Live' ? 'button-date__active' : ''}`}
          onClick={() => {
            setActiveButtonDate('Live');
          }}
        >
          Live
        </Button>
        <Button
          variant='outlined'
          size='small'
          className={`button-date ${activebuttonDate === 'Fresh' ? 'button-date__active' : ''}`}
          onClick={() => {
            setActiveButtonDate('Fresh');
          }}
        >
          Fresh
        </Button>
        <Button
          variant='outlined'
          size='small'
          className={`button-date ${activebuttonDate === 'Frozen' ? 'button-date__active' : ''}`}
          onClick={() => {
            setActiveButtonDate('Frozen');
          }}
        >
          Frozen
        </Button>
      </>
    );
  };

  return (
    <div css={cssStyles} onClick={onClick}>
      <div className='root'>
        <Grid container>
          <Grid container justify='space-between' item xs={12} className='heading-name-buttons'>
            <Grid className='name'>{baitReport.name}</Grid>
            <Grid>
              <PeriodButtons />
            </Grid>
          </Grid>

          <Grid container className='items-container' alignItems='flex-start' alignContent='flex-start'>
            {baitReport.itemsGroup.map((y, index) => (
              <Grid container item key={index} className='unit-card'>
                <div className='item-title'>{y.name}</div>

                {y.items.map((x, index) => (
                  <Grid key={index} container justify='space-between' className='item' alignItems='flex-start'>
                    <Grid item>
                      <span className='item-unit'>{x.type}</span> {x.quantity}
                    </Grid>
                    <Grid item>{x.date}</Grid>
                  </Grid>
                ))}
                <div className='item-total'> Total: {y.total}</div>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

ZCardBaitReport.defaultProps = {};

const cssStyles = css`
  .root {
    background-color: #f9f9f9;
    border: solid 1px rgba(112, 112, 112, 0.14);
    border-radius: 5px;
    border: solid 1px rgba(112, 112, 112, 0.14);
    border-radius: 5px;
    margin: 0px 10px;
    margin-bottom: 20px;
    height: 270px;
    :hover {
      cursor: pointer;
    }
  }

  .unit-card {
    margin: 8px 15px;
    padding: 10px;
    border: solid 1px rgba(112, 112, 112, 0.14);
    border-radius: 5px;
    border: solid 1px rgba(112, 112, 112, 0.14);
    border-radius: 5px;
  }

  .heading-name-buttons {
    margin-top: 10px;
    margin-right: 5px;
  }

  .name {
    margin-left: 10px;
    margin-top: 5px;
    letter-spacing: normal;
    font-size: 14px;
    text-align: left;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.54);
  }

  .items-container {
    width: 100%;
    margin: 8px 0px;
    height: 210px;
    overflow: auto;
  }

  .item-title {
    width: 100%;
    margin: 6px 10px;
    letter-spacing: normal;
    font-size: 16px;
    text-align: left;
    color: rgba(0, 0, 0, 0.68);
    font-weight: 700;
    font-style: italic;
  }
  .item-total {
    width: 100%;
    margin: 6px 10px;
    letter-spacing: normal;
    font-size: 16px;
    text-align: left;
    color: #54a0ff;
    font-weight: 700;
  }
  .item {
    width: 100%;
    margin: 6px 10px;
    letter-spacing: normal;
    font-size: 14px;
    text-align: left;
    color: rgba(0, 0, 0, 0.68);

    .item-unit {
      font-weight: 700;
    }
  }
`;

export default ZCardBaitReport;
