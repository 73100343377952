import * as React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Icon,
} from "@material-ui/core";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import { Link, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SummaryIcon from "@material-ui/icons/BarChart";

interface Props {}

const ZDrawer: React.FC<Props> = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const classes = useStyles();
  const history = useHistory();
  const handleListItemClick = (index: number) => {
    setSelectedIndex(index);
  };
  return (
    <div>
      <Drawer variant="permanent" className={classes.drawer}>
        <div className={classes.drawer}>
          <div
            onClick={() => history.push("/dashboard-summary")}
            className={classes.logo}
          >
            <img src="/images/logo.png" alt="logo" />
          </div>

          <List>
            <ListItem>
              <ListItemText primary="Baitshop" className="list-heading" />
            </ListItem>

            <Divider className={classes.divider} />

            <ListItem
              button
              component={Link}
              to="/dashboard-summary"
              classes={{ selected: classes.activeTab }}
              selected={
                history.location.pathname === "/dashboard-summary" ||
                history.location.pathname === "/"
              }
              onClick={() => handleListItemClick(0)}
            >
              <ListItemIcon>
                <SummaryIcon className={classes.icons}></SummaryIcon>
              </ListItemIcon>
              <ListItemText className={classes.tabText} primary="Summary" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/dashboard-baits"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/dashboard-baits"}
              onClick={() => handleListItemClick(1)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>shopping_basket</Icon>
              </ListItemIcon>
              <ListItemText primary="Baits" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/dashboard-reservations"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/dashboard-reservations"}
              onClick={() => handleListItemClick(3)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>assignment_turned_in</Icon>
              </ListItemIcon>
              <ListItemText primary="Reservations" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/dashboard-reports"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/dashboard-reports"}
              onClick={() => handleListItemClick(4)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>report</Icon>
              </ListItemIcon>
              <ListItemText primary="Reports" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/dashboard-fishing-reports"
              classes={{ selected: classes.activeTab }}
              selected={
                history.location.pathname === "/dashboard-fishing-reports"
              }
              onClick={() => handleListItemClick(5)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>poll</Icon>
              </ListItemIcon>
              <ListItemText primary="Fishing Reports" />
            </ListItem>
          </List>

          <Divider className="divider" />
          <List>
            <ListItem>
              <ListItemText primary="Tutorials" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/guides-reservation"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/guides-reservation"}
              onClick={() => handleListItemClick(6)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>import_contacts</Icon>
              </ListItemIcon>
              <ListItemText primary="Reservations" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/service-rentals"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/service-rentals"}
              onClick={() => handleListItemClick(6)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>import_contacts</Icon>
              </ListItemIcon>
              <ListItemText primary="Service & Rentals" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/guides-refunds"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/guides-refunds"}
              onClick={() => handleListItemClick(7)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>import_contacts</Icon>
              </ListItemIcon>
              <ListItemText primary="Refund" />
            </ListItem>

            <ListItem
              button
              component={Link}
              to="/guides-download"
              classes={{ selected: classes.activeTab }}
              selected={history.location.pathname === "/guides-download"}
              onClick={() => handleListItemClick(7)}
            >
              <ListItemIcon>
                <Icon className={classes.icons}>import_contacts</Icon>
              </ListItemIcon>
              <ListItemText primary="PDF Downloads" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  toolbar: {
    marginTop: 60,
  },
  drawer: {
    width: 240,
    background: "#263544",
    color: "white",
    height: "100%",
  },
  icons: {
    color: "white",
    fontSize: 22,
  },
  divider: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
  },
  activeTab: {
    backgroundColor: "#20a8d8 !important",
  },
  tabText: {
    fontSize: "0.95rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: "0.00938em",
  },
  logo: {
    display: "flex",
    alignItems: "center",
    height: 64,
    cursor: "pointer",
    boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.2),
                    0px 4px 5px 0px rgba(0, 0, 0, 0.14),
                    0px 1px 10px 0px rgba(0, 0, 0, 0.12)`,
    "& img": {
      marginLeft: 15,
      marginTop: 6,
      height: 30,
    },
  },
}));

ZDrawer.defaultProps = {};

export default ZDrawer;
