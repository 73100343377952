import * as React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import { AuthContext } from '../models_context/AuthContext';
import { useHistory } from 'react-router-dom';
/** @jsx jsx */
import { jsx, css } from '@emotion/core';
import { Menu, MenuItem, Avatar, Grid, Divider, Icon } from '@material-ui/core';
import { AdminContext } from '../models_context/AdminContext';
import { FirestoreService } from '../models_services/FirestoreService';

interface Props {}

const ZNavbar: React.FC<Props> = () => {
  const { state: authState, dispatch: dispatchState } = React.useContext(AuthContext);
  const { state, dispatch } = React.useContext(AdminContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const updateBaitStand = async (index: number, id: string) => {
    setAnchorEl(null);
    dispatch({ type: 'SET_SELECTED_STAND_BY_INDEX', payload: index });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div css={cssStyles}>
      <AppBar position='fixed' className={`${authState.isAuthenticated ? 'root' : 'root-login'}`}>
        <Toolbar>
          <div className='grow'></div>

          {!authState.isAuthenticated && (
            <Button color='inherit' component={Link} to='/login'>
              Sign in
            </Button>
          )}

          {authState.isAuthenticated && (
            <>
              <Button
                aria-controls='simple-menu'
                aria-haspopup='true'
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <Avatar alt='' src={state.stand?.profileUrl} className='avatar-small' />
                {state.stand?.name}
              </Button>
              <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose} css={cssMenu}>
                {state.stands.map((stand, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      updateBaitStand(index, stand.documentId);
                    }}
                    className='menu-item'
                  >
                    <Grid container alignItems='center'>
                      <Grid item xs={1}>
                        <Avatar className='avatar' alt='' src={stand.profileUrl} />
                      </Grid>
                      <Grid container direction='column' item xs={11}>
                        <Grid className='baitshop-name'>{stand.name}</Grid>
                        <Grid className='baitshop-address'>{stand.address}</Grid>
                      </Grid>
                    </Grid>
                  </MenuItem>
                ))}
                <Divider />
                <MenuItem
                  component={Link}
                  className='sign-out'
                  to='/'
                  onClick={() => {
                    setAnchorEl(null);
                    dispatchState({ type: 'SIGN_OUT' });
                  }}
                >
                  <Icon className='icon'>exit_to_app</Icon> Sign Out
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};

const cssMenu = css`
  .avatar {
    width: 20px;
    height: 20px;
  }
  .baitshop-name {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 700;
  }
  .baitshop-address {
    margin-left: 10px;
    font-size: 12px;
    font-weight: 500;
  }
  .sign-out {
    color: red;

    .icon {
      margin-right: 10px;
    }
  }
`;

const cssStyles = css`
  .root {
    z-index: 10;
    width: calc(100% - 240px);
    background-color: white;
    color: black;
  }

  .root-login {
    z-index: 10;
    width: 100%;
    background-color: white;
    color: black;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.05), 0px 4px 5px 0px rgba(0, 0, 0, 0.05), 0px 1px 10px 0px rgba(0, 0, 0, 0.05);
    background-color: #f3f6f8;
  }

  .avatar-small {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .grow {
    flex-grow: 1;
  }
`;

ZNavbar.defaultProps = {};

export default ZNavbar;
