import * as React from "react";
import { Grid } from "@material-ui/core";
import ZText from "../../components/ZText";
/** @jsx jsx */
import { jsx, css } from "@emotion/core";
import ResponsiveCarouselNext from "../../components/ZResponsiveCarouselNext";
import breakpoint from "../../app_utils/Breakpoints";

interface Props {}

const GuideServiceReservationsAndRentals: React.FC<Props> = () => {
  return (
    <div>
      <Grid css={cssStyles}>
        <ZText heading> Service Reservations and Rental</ZText>
        <ZText>
          Within the services tab of the Admin app, Shop owners are able to rent
          or reserve products and/or services. This capability enables Bait
          Shops to advertise, reserve, and process payments for things such as
          Kayaks, Canoes, Fishing Gear, Boats, or even book a Fishing Guide.
        </ZText>
        <ZText>
          The capability was designed and developed at the request of and
          working closely with several Bait Shops who were in some cases
          expanding into new area or wanting to streamline a portion of their
          business.
        </ZText>
        <ZText>
          These features were designed to enable complete flexibility, allowing
          bait shops to book, reserve, or simply sell virtually anything (legal
          of course).
        </ZText>
        <Grid item>
          <ZText subheading>
            To enable rentals/service reservations is simple...on the info tab
            of the admin app
          </ZText>

          <ZText subheading>
            Just check the box to allow service reservations
          </ZText>
        </Grid>

        <Grid
          container
          justify="center"
          alignItems="center"
          css={cssGridWrapper}
        >
          <Grid item md={5} xs={12}>
            <img src="/images/baitshop_enable_rentals_1.png" alt="" />
          </Grid>
        </Grid>

        <ZText
          subheading
          css={css`
            margin: auto;
            margin-top: 70px;
          `}
        >
          To create an advertisement, create a rental offering, reserve a
          parking space at the boat ramp, or even book a guide....you simply
          create an offer.
        </ZText>

        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsAdvertisements}
        ></ResponsiveCarouselNext>

        <ZText
          subheading
          textAlign="center"
          css={css`
            margin: auto;
            margin-top: 40px;
          `}
        >
          <div>To Enable Prepayment and/or Reservations</div>
          <div>It's simple, click on the 'Allow reservations box"</div>
          <div>And the options around the reservation will appear:</div>
        </ZText>

        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsEnablePayment}
        ></ResponsiveCarouselNext>

        <ZText
          subheading
          textAlign="center"
          css={css`
            margin: auto;
            margin-top: 20px;
            max-width: 60%;
            font-style: italic;
          `}
        >
          Your new rental, service offering, or advertisement appears inside the
          Angler's app....and it's simple for the Angler to pre-pay for products
          such as gas or ice, reserve a Kayak, or simply see what you have to
          offer.
        </ZText>

        <ZText
          subheading
          textAlign="center"
          css={css`
            margin: auto;
            margin-top: 70px;
          `}
        >
          On the services tab of the Angler's app, you can see our ads and
          offers.
        </ZText>

        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsServices}
        ></ResponsiveCarouselNext>

        <ZText
          subheading
          textAlign="center"
          css={css`
            margin: auto;
            margin-top: 10px;
          `}
        >
          Processing a Rental
        </ZText>
        <ResponsiveCarouselNext
          CarouselItems={CarouselItemsProcessRentals}
        ></ResponsiveCarouselNext>
      </Grid>
    </div>
  );
};
const cssStyles = css`
  max-width: 720px;
  margin: auto;
`;

interface ICarouselItem {
  image: string;
  body: string;
}

const cssGridWrapper = css`
  padding: 30px 30px;
  max-width: 1080px;
  margin: auto;

  img {
    max-height: 350px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    @media ${breakpoint.md} {
      max-height: 600px;
    }
  }
`;

const CarouselItemsServices: ICarouselItem[] = [
  {
    body: "1. The Angler clicks on reserve",
    image: "/images/baitshop_services_tab_1.png",
  },
  {
    body: "2. The Angler specifies a date and time",
    image: "/images/baitshop_services_tab_2.png",
  },
  {
    body: `3.  They can add hours (or days). The proceed to pay, enter their credit Card....and the Bait Shop get's a notification.`,
    image: "/images/baitshop_services_tab_3.png",
  },
];
const CarouselItemsProcessRentals: ICarouselItem[] = [
  {
    body: "1. Click the Services Button on the bottom",
    image: "/images/baitshop_process_rental_1.png",
  },
  {
    body: "Then click on the reservation to open",
    image: "/images/baitshop_process_rental_2.png",
  },
  {
    body:
      "3. You can accept or reject the reservation a) if you reject you can provide a  reason",
    image: "/images/baitshop_process_rental_3.png",
  },
  {
    body: "4. You provide a confidence level",
    image: "/images/baitshop_process_rental_4.png",
  },
  {
    body: "5. Up to 7 days before, you confirm or reject",
    image: "/images/baitshop_process_rental_5.png",
  },
  {
    body:
      "6.When the user show up, and picks the equipment up you click complete",
    image: "/images/baitshop_process_rental_6.png",
  },
  {
    body:
      "7. When the user returns the equipment, you open the reservation and hit return security deposit and it will be automatically refunded.",
    image: "/images/baitshop_process_rental_6.png",
  },
];
const CarouselItemsAdvertisements: ICarouselItem[] = [
  {
    body: "1. Click on Add Offer",
    image: "/images/baitshop_advertisement1.png",
  },
  {
    body:
      '2. Enter a Title of the offer such as "Kayak Rentals", a Description such as "Fishing Kayaks available to rent by the hour, three hour minimum"',
    image: "/images/baitshop_advertisement2.png",
  },

  {
    body:
      "3. Click on the + sign to add an image (Image needs to be on your phone)",
    image: "/images/baitshop_advertisement3.png",
  },
];
const CarouselItemsEnablePayment: ICarouselItem[] = [
  {
    body: "1. Days of week rentals are available.",
    image: "/images/baitshop_enable_pay_1.png",
  },
  {
    body:
      "2. Price either per hour, half day, full day. Security Deposit, there is a simple way to refund the security deposit when the equipment is returned.",
    image: "/images/baitshop_enable_pay_2.png",
  },
  {
    body:
      "3. Service Fee (optional): This can be used for delivery, or anything you want based on what you specify in the offer. Sales Tax Percentage",
    image: "/images/baitshop_enable_pay_2.png",
  },

  {
    body: `4 .That's it....you are good to go.(You can edit is by simply clicking on the pencil on service tab.)`,
    image: "/images/baitshop_enable_pay_3.png",
  },
];

GuideServiceReservationsAndRentals.defaultProps = {};

export default GuideServiceReservationsAndRentals;
