import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  title: string;
  open: boolean;
  message: any;
  submitForm?: () => void;
  cancelForm?: () => void;
}

const ZDialogForm: React.FC<Props> = ({ title, open, message, submitForm, cancelForm }) => {
  return (
    <div>
      <Dialog open={open} onClose={cancelForm} maxWidth={'sm'} disableBackdropClick>
        <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>{message}</DialogContentText>
        </DialogContent>
        {!submitForm || !cancelForm ? null : (
          <>
            <DialogActions>
              <Button onClick={cancelForm} color='secondary' type='submit'>
                Cancel
              </Button>
              <Button onClick={submitForm} color='primary' type='submit'>
                Save
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ZDialogForm;
